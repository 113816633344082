@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiAyp8kv8JHgFVrJJLmE3tF.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLmv1plEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLm21llEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiGyp8kv8JHgFVrJJLedw.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLmg1hlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLmr19lEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLmy15lEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLm111lEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLm81xlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiGyp8kv8JHgFVrLPTedw.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLFj_V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDz8V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrFJA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLGT9V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLEj6V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLCz7V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDD4V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLBT5V1s.ttf) format('truetype');
}
* {
  box-sizing: border-box;
}
body {
  margin: 0px;
  padding: 0px;
  font-family: 'Poppins', sans-serif !important;
  color: #6d747a;
  font-size: 14px;
  font-weight: 400;
}
h3 {
  font-weight: 600;
  font-size: 18px;
  margin: 0px;
  padding: 0px;
  margin-bottom: 10px;
  color: #4b525c;
}
.navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.8) !important;
}
.navbar-nav .nav-link:hover {
  color: #fff !important;
}
.add-event-sec {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  padding: 30px;
  margin-bottom: 30px;
}
.txt-w {
  color: #fff;
}
.txt-b {
  color: #5b358c;
}
.light-gbg {
  background-color: #fcfcfc;
}
.imp-txt {
  font-size: 20px;
}
.imp-txt p {
  font-size: 18px;
  font-weight: 300;
}
.sub-hd {
  font-size: 20px;
}
a {
  color: #a2a2a2;
  text-decoration: none;
}
a .nav-link {
  color: #393f49;
  font-size: 13px;
}
a:hover {
  color: #000;
}
a.txt-lnk {
  color: #000;
  font-size: 14px;
  text-decoration: none;
}
a.txt-lnk:hover {
  color: #5b358c;
}
a:hover {
  color: #000;
}
.purple-bg {
  background-color: #5a4099;
}
.pink-bg {
  background-color: #ec008c;
}
.pub-img {
  position: absolute;
  vertical-align: middle;
  right: 30px;
  z-index: -1;
}
.pub-img img {
  width: 70px;
}
.pb-img {
  position: absolute;
  vertical-align: middle;
  right: 10px;
  z-index: -1;
}
.pb-img img {
  width: 120px;
}
h1 {
  font-size: 22px;
  font-weight: 400;
  color: #393f49;
  margin: 0px;
  padding: 0px;
}
.nav-link {
  color: #393f49;
  font-size: 13px;
}
.avatar {
  border-radius: 50%;
}
.avatar img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
}
.profile-pic {
  border-radius: 5%;
  border: 1px solid #ccc;
  width: 80px;
  height: 80px;
  object-fit: cover;
  padding: 3px;
  position: relative;
}
.lst-staff-pic {
  width: 60px;
  height: 60px;
  padding: 3px;
  border: 1px solid #ccc;
  object-fit: cover;
}
.lnk-camera {
  position: absolute;
  left: 70px;
  top: 30px;
  font-size: 20px;
  color: #5a4099;
}
.profile-mnu .dropdown-toggle::after {
  border: none;
}
.profile-mnu .dropdown-menu {
  right: 0px;
  left: auto;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 12px;
  color: #a4a6a8;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e6ecf0;
  border-radius: 0.25rem;
}
.dropdown-item .fas,
.dropdown-item .svg-inline--fa {
  color: #bcbec0;
  margin-right: 5px;
  width: 20px;
}
.dropdown-item:hover {
  background-color: #f8f9fa;
}
.dropdown-item:hover .fas,
.dropdown-item:hover .svg-inline--fa {
  color: #545658;
}
.mvp-sidebar {
  position: fixed;
  z-index: 1000;
  top: 17px;
  width: 260px;
  height: 100%;
  background: #fff;
  transition: all 0.6s ease-in-out;
  margin-top: 52px;
  border-right: 1px solid #e6ecf0;
}
.mvp-sidebar.insurere-p {
  top: 44px;
}
.mvp-sidebar .nav-link {
  display: block;
  text-decoration: none;
  font-size: 13px;
  position: relative;
  color: #393f49;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}
.mvp-sidebar .nav-link.active {
  color: #fff;
  background-color: #e6007e;
}
.mvp-sidebar .nav-link.active .fas,
.mvp-sidebar .nav-link.active .svg-inline--fa,
.mvp-sidebar .nav-link.active .far {
  color: #fff;
}
.mvp-sidebar .nav-link span {
  margin-right: 5px;
}
.mvp-sidebar .nav-link .fas,
.mvp-sidebar .nav-link .svg-inline--fa,
.mvp-sidebar .nav-link .far {
  color: #bcbec0;
  border-radius: 50px;
  height: 30px;
  width: 40px;
  padding: 7px;
  text-align: center;
  transition: 0.3s ease all;
}
.mvp-sidebar .nav-link:hover {
  color: #fff;
  background-color: #e6007e;
}
.mvp-sidebar .nav-link:hover .fas,
.mvp-sidebar .nav-link:hover .svg-inline--fa,
.mvp-sidebar .nav-link:hover .far {
  color: #fff;
  transition: 0.3s ease all;
}
.mvp-sidebar .list-group {
  font-size: 13px;
}
.mvp-sidebar .list-group .svg-inline--fa {
  color: #bcbec0;
}
.mvp-sidebar .list-group span {
  color: #393f49;
}
.mvp-sidebar .list-group a {
  color: #393f49;
}
.mvp-sidebar .list-group a:hover .svg-inline--fa {
  color: #e6007e;
}
.content-page {
  overflow: hidden;
  margin-left: 260px;
  padding: 70px 0px 0px 0px;
  min-height: 100vh;
  transition: all 0.3s ease-in-out;
  width: calc(100% - 260px);
}
.content-page.no-ml {
  margin: 0 auto;
  width: 100%;
}
.content-page .page-title {
  border-bottom: 1px solid #e6ecf0;
  position: relative;
  display: block;
  padding: 12px 24px;
  margin-bottom: 15px;
}
.content-page.innerpage-cntarea {
  width: 100%;
  margin-left: 0px;
}
.page-title {
  border-bottom: 1px solid #e6ecf0;
  position: relative;
  display: block;
  padding: 12px 0px;
  margin-bottom: 15px;
}
.nav-pad {
  padding: 18px 15px 15px;
}
.navbar-brand {
  margin: 0px;
  padding: 0px;
}
.navbar-brand img {
  height: 55px;
  width: auto;
}
.navbar {
  border-bottom: 1px solid #e5007d;
  background-color: #e5007d;
  padding: 0.5rem 0rem;
}
.breadcrumb {
  padding: 0px;
  margin-bottom: 0.5rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0rem;
}
.breadcrumb .breadcrumb-item {
  color: #9d9fa1;
  font-size: 11px;
  margin-left: 0px;
}
.breadcrumb .breadcrumb-item a {
  text-decoration: none;
  color: #bcbec0;
}
.breadcrumb .breadcrumb-item::before {
  content: "" !important;
}
.content-area {
  position: relative;
  display: block;
  min-height: 400px;
  z-index: 1;
}
.movop-summary {
  z-index: 1;
  color: #fff;
  padding: 30px 22px;
}
.movop-summary .summary-count {
  font-size: 70px;
  font-weight: 100;
  line-height: 60px;
}
.movop-summary .summary-hd {
  font-size: 24px;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
  line-height: 28px;
}
.movop-summary .summary-hd span {
  font-size: 24px;
  font-weight: normal;
  margin: 0px;
  padding: 0px;
}
.widget-actions {
  position: relative;
  padding: 0px 30px 20px;
  border: 1px solid #e6ecf0;
  border-radius: 0.25rem;
}
.widget-actions .widget-title {
  display: flex;
  align-items: center;
  padding: 20px 20px;
  margin: 0px -30px 20px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e6ecf0;
  color: #393f49;
  font-size: 14px;
}
.widget-actions .widget-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}
.widget-actions .widget-content .list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
  border-bottom: 1px solid #e6ecf0;
  padding: 0.9rem 1rem !important;
  word-wrap: break-word;
}
.widget-actions .widget-content .list-group-flush .list-group-item .movop-link {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
}
.widget-actions .widget-content .list-group-flush .list-group-item .movop-link .widget-img {
  display: inline-flex;
  flex: 0 0 35px;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.145rem;
  line-height: 0;
  color: #d7dfea;
  background-color: #ececec;
  border-radius: 100%;
  transition: opacity 0.15s ease-in-out, color 0.15s ease-in-out, background-color 0.15s ease-in-out;
}
.widget-actions .widget-content .list-group-flush .list-group-item .movop-link .widget-img::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 34px;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  z-index: 1;
}
.widget-actions .widget-content .list-group-flush .list-group-item .movop-link .widget-body {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}
.widget-actions .widget-content .list-group-flush .list-group-item .movop-link .widget-body .widget-ttl {
  color: #393f49;
  font-size: 13px;
  text-transform: capitalize;
}
.widget-actions .widget-content .list-group-flush .list-group-item .movop-link .widget-body .widget-subttl {
  display: block;
  margin-bottom: -4px;
  color: #a4a6a8;
  font-size: 11px;
}
.widget-actions .widget-content .list-group-flush .list-group-item .movop-link:hover .widget-ttl {
  color: #5a4099;
}
.widget-actions .widget-content .list-group-flush:first-child {
  border-top-width: 0;
}
.widget-actions .widget-content:last-child .list-group-item:last-child {
  border-bottom-width: 0;
}
.widget-actions .widget-list {
  margin-right: -30px;
  margin-bottom: -20px;
  margin-left: -30px;
  margin-top: -20px;
}
.list-group-item {
  border: 0px;
  padding: 0.3rem 1rem !important;
}
.footer {
  border-top: 1px solid #e6ecf0;
  font-size: 11px;
  color: #808388;
  bottom: 0px;
  margin-top: auto;
}
.footer.darkbg {
  background-color: #474747;
  color: #f2f2f2;
  font-size: 11px;
  font-weight: 300;
  padding: 30px 0px;
}
.footer.darkbg a {
  color: #f2f2f2;
  text-decoration: none;
}
.footer.darkbg a:hover {
  color: #fff;
}
.movop-drop .dropdown-toggle {
  border: none;
  background-color: transparent;
  font-size: 12px;
  color: #bcbec0;
  font-weight: normal;
}
.movop-drop .dropdown-toggle::after {
  border: none !important;
  margin: 0px;
  padding: 0px;
  border: 0px;
}
.movop-drop .dropdown-toggle:focus {
  outline: none;
}
.movop-drop .dropdown-toggle:hover {
  color: #393f49;
}
/*Login Page Start*/
.login-bg {
  background: #e6007e;
  background: linear-gradient(132deg, #e6007e 0%, #5b358c 100%);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.login-bg::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}
.login-bg .mvector {
  background: url(../images/m-vector.svg) center center no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.img-index {
  position: relative;
  z-index: 11;
  width: 270px;
}
.form-control.line-input {
  border: none;
  border-bottom: 1px solid #d9d9d9;
  font-size: 14px;
  color: #636363;
}
.login-box {
  position: relative;
  z-index: 11;
  background-color: #fff;
  padding: 40px 45px;
  width: 500px;
}
.login-box h1 {
  font-size: 24px;
  color: #000;
}
.login-box .form-control:focus {
  box-shadow: none !important;
  border-bottom: 1px solid #d9d9d9;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: transparent !important;
}
.form-control:focus {
  box-shadow: none;
}
.form-label {
  font-size: 15px;
  color: #000;
}
*:focus {
  border: 0;
  outline: none;
  box-shadow: none;
}
::-webkit-input-placeholder {
  /* Edge */
  font-size: 14px;
  color: #d6d7d8;
  font-family: 'Poppins', sans-serif;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 14px;
  color: #d6d7d8;
  font-family: 'Poppins', sans-serif;
}
::placeholder {
  font-size: 14px;
  color: #e3e8ec;
  font-family: 'Poppins', sans-serif;
}
.form-control::placeholder {
  color: #d6d7d8;
  opacity: 1;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.2rem 0.375rem 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #c8c8c8;
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ced4da !important;
  border-radius: 0rem;
}
.movop-btn {
  width: 100%;
  border-radius: 60px;
  padding: 12px;
  background: #e6007e;
  background: linear-gradient(132deg, #e6007e 0%, #5b358c 100%);
  border: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  transition: 0.3s ease all;
}
.movop-btn.btn-primary {
  transition: 0.3s ease all;
}
.movop-btn.btn-primary:focus {
  color: #fff !important;
  transition: 0.3s ease all;
}
.movop-btn.btn-primary:hover {
  background: #5b358c;
  color: #fff;
  transition: 0.3s ease all;
}
.movop-btn:hover {
  background: #5b358c;
  transition: 0.3s ease all;
}
.movop-btn:focus {
  color: #fff;
}
button:focus {
  outline: none;
}
/*Login Page End*/
.icn-abso {
  position: absolute;
  top: 0px;
  right: -5px;
  background-color: transparent !important;
  border-color: transparent !important;
  z-index: 11;
  color: #e5007d;
}
/*Insurer Dashboard Start*/
.img-box {
  margin-bottom: 30px;
  position: relative;
  width: 240px;
}
.img-box .img-company {
  margin-bottom: 5px;
  padding: 15px;
  border: 1px solid #e6ecf0;
}
.img-box .img-company img {
  width: 100%;
  height: 140px;
  object-fit: cover;
}
.img-box .img-company .overlay-cnt {
  transition: 0.3s ease all;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #e6ecf0;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 26px;
  align-items: center;
  justify-content: center;
  display: flex;
  opacity: 1;
}
.img-box .img-company .overlay-cnt a,
.img-box .img-company .overlay-cnt button {
  border-radius: 60px;
  padding: 12px;
  background: #e6007e;
  background: linear-gradient(132deg, #e6007e 0%, #5b358c 100%);
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  opacity: 0;
}
.img-box .img-hd {
  font-size: 14px;
  color: #808388;
  text-align: center;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
  text-transform: capitalize;
}
.img-box:hover .img-company {
  margin-bottom: 5px;
}
.img-box:hover .img-company .overlay-cnt {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 26px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid #e6ecf0;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 1;
}
.img-box:hover .img-company .overlay-cnt a,
.img-box:hover .img-company .overlay-cnt button {
  border-radius: 60px;
  padding: 12px;
  background: #e6007e;
  background: linear-gradient(132deg, #e6007e 0%, #5b358c 100%);
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  transition: 0.3s ease all;
  opacity: 1;
}
.img-box:hover .img-hd {
  font-size: 14px;
  color: #000;
  text-transform: capitalize;
}
/*Insurer Dashboard End*/
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #bcbec0;
}
/*Broker Dashboard Start*/
.inner-mvp-sidebar {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 260px;
  height: 100%;
  background: #fff;
  transition: all 0.6s ease-in-out;
  margin-top: 146px;
  border-right: 1px solid #e6ecf0;
}
.inner-mvp-sidebar ul li a {
  display: block;
  text-decoration: none;
  font-size: 13px;
  position: relative;
  padding: 5px 0px;
  color: #393f49;
}
.inner-mvp-sidebar ul li a span {
  margin-right: 5px;
}
.inner-mvp-sidebar ul li a .fas,
.inner-mvp-sidebar ul li a .far {
  font-size: 14px;
  color: #bcbec0;
  border-radius: 50px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  transition: 0.3s ease all;
}
.inner-mvp-sidebar ul li a:hover {
  color: #5b358c;
}
.inner-mvp-sidebar ul li a:hover .fas,
.inner-mvp-sidebar ul li a:hover .far {
  background-color: #eaeaea;
  transition: 0.3s ease all;
  height: 30px;
  width: 30px;
}
.inner-content-page {
  overflow: hidden;
  margin-left: 260px;
  padding: 0px 0px 0px 0px;
  min-height: 100vh;
  transition: all 0.3s ease-in-out;
  width: calc(100% - 260px);
}
.inner-content-page.innerpage-cntarea {
  width: 100%;
  margin-left: 0px;
}
.movop-lst-group {
  margin: 0px;
  padding: 0px 15px !important;
}
.movop-lst-group .movop-lst-item {
  margin: 0px;
  padding: 0px;
  list-style: none;
  font-size: 13px;
  color: #6c757d;
  line-height: 22px;
}
.lg-bg {
  background-color: #e6ecf0;
}
.pd-sm {
  padding: 10px;
  margin-bottom: 1px;
  border-bottom: 1px solid #e6ecf0;
}
.contact-info {
  font-size: 12px;
}
.contact-info .fas {
  font-size: 14px;
  color: #bcbec0;
  border-radius: 50px;
  height: 30px;
  width: 30px;
  line-height: 20px;
  text-align: center;
  transition: 0.3s ease all;
}
.contact-info a {
  font-size: 12px !important;
  padding: 0px !important;
  display: inline-block !important;
}
ol {
  margin: 0px;
  padding: 0px;
}
ol li {
  margin: 0px;
  padding: 0px;
  margin-left: 15px;
}
.thumb-staff {
  width: auto;
  height: 180px;
  object-fit: cover;
  border: 1px solid #e6ecf0;
  border-radius: 3px;
  margin-bottom: 5px;
}
.staff-hd {
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.staff-hd.card-title {
  font-size: 14px;
}
.staff-hdh5 {
  font-size: 14px;
}
.staff-hd small {
  font-size: 12px;
  display: block;
}
.event-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  height: 180px;
}
.event-box .event-overlay {
  position: absolute;
  width: 100%;
  height: 0%;
  overflow: hidden;
  background: transparent;
  bottom: 0px;
  left: 0px;
  right: 0px;
  transition: 0.3s ease all;
  color: #fff;
  font-size: 10px;
}
.event-box .event-overlay a {
  color: #fff;
  text-decoration: none;
  margin: 0px 3px;
}
.event-box .event-overlay a .fab {
  font-size: 16px;
}
.event-box:hover .event-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: transparent;
  transition: 0.3s ease all;
  background: #5b358c;
  background: linear-gradient(0deg, #5b358c 0%, rgba(91, 53, 140, 0) 100%);
}
/*Broker Dashboard End*/
/*broker form*/
.movop-frm {
  font-size: 14px;
  color: #4b515b;
}
.movop-frm .form-label {
  font-size: 14px;
  color: #4b515b;
}
.movop-frm ::-webkit-input-placeholder {
  /* Edge */
  font-size: 14px;
  color: #d6d7d8;
  font-family: 'Poppins', sans-serif;
}
.movop-frm :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 14px;
  color: #d6d7d8;
  font-family: 'Poppins', sans-serif;
}
.movop-frm ::placeholder {
  font-size: 14px;
  color: #d6d7d8;
  font-family: 'Poppins', sans-serif;
}
.movop-frm .form-control {
  border: 1px solid #f2f2f2;
}
.react-datepicker-ignore-onclickoutside {
  border: none !important;
  border-bottom: 1px solid #f2f2f2 !important;
  width: 100% !important;
  height: 45px;
}
.react-datepicker__input-container input {
  width: 100%;
  height: 45px;
  border: transparent !important;
  border-bottom: 1px solid #f2f2f2 !important;
  padding-left: 15px;
  color: #636363;
}
.btn-bdr {
  background-color: transparent;
  border: 1px solid #dadada;
  color: #4b515b;
  border-radius: 50px;
  font-size: 13px;
  margin-right: 5px;
  padding: 8px 20px !important;
  background: linear-gradient(to bottom, white 0%, #dcdcdc 100%);
}
.btn-bdr:hover {
  background: #e6007e;
  background: linear-gradient(132deg, #e6007e 0%, #5b358c 100%);
  color: #fff !important;
  border: 1px solid #fff;
}
/*Broker Deashboard End*/
/*How to trade with us*/
.trade-banner {
  height: 300px;
  background: url(../images/Partnership-Handshake.jpg) center center no-repeat;
  background-size: cover;
  position: relative;
}
.trade-banner .pro-img {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: rgba(233, 0, 127, 0.5);
  border-radius: 50px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  font-size: 20px;
}
.trade-banner .pro-img a {
  color: #fff;
  text-decoration: none;
}
.trade-banner .pro-img a:hover {
  color: #5a4099;
}
h2 {
  font-size: 40px;
  font-weight: bold;
  color: #393f49;
}
.icn-lsting {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  color: #6d747a;
  line-height: 24px;
}
.icn-lsting li {
  margin: 0px;
  padding: 0px;
  list-style: none;
  margin-bottom: 25px;
  position: relative;
  padding-left: 40px;
}
.icn-lsting li::before {
  position: absolute;
  content: '';
  display: inline-flex;
  height: 2.3em;
  width: 2.3em;
  background-image: url(../images/bullet.svg);
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 0.5em;
  left: 0;
}
.cta-banner {
  background: url(../images/Partnership-Handshake.jpg) center center no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  z-index: 1;
  padding: 50px 0px 40px;
}
.cta-banner::after {
  content: "";
  background: #e9007f;
  background: linear-gradient(0deg, rgba(233, 0, 127, 0.59427521) 0%, rgba(91, 53, 140, 0.65309874) 100%);
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  color: #fff;
}
.cta-banner ul {
  color: #fff;
  margin: 0px;
  padding: 0px;
}
.cta-banner ul li {
  margin: 0px;
  padding: 0px;
  list-style: none;
  color: #fff;
  padding-left: 65px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
}
.numbering {
  border-radius: 50px;
  border: 1px solid #fff;
  height: 50px;
  width: 50px;
  text-align: center;
  display: flex;
  position: absolute;
  left: 0px;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 200;
}
.info-box {
  display: flex;
  flex-direction: row;
}
.info-box .info-icn {
  margin-right: 15px;
}
.info-box .info-icn img {
  width: 50px;
  height: auto;
}
.btn-default {
  border: 1px solid #f2f2f2;
  border-radius: 50px;
  padding: 8px 20px;
  color: #fff;
}
.btn-default:hover {
  background-color: #fff;
  color: #5a4099;
}
/*broker registration form*/
.BrokerRegister {
  color: #6c757d;
  font-size: 13px;
}
.BrokerRegister .form-label {
  font-size: 13px;
  color: #6c757d;
}
.BrokerRegister .form-control {
  border: none;
  padding: 13px;
  font-size: 13px;
  border-bottom: 1px solid #e6ecf0;
  border-radius: 0px;
  color: #6c757d;
}
.BrokerRegister ul {
  margin: 0px;
  padding: 0px;
}
.BrokerRegister ul li {
  margin: 0px;
  padding: 0px;
  list-style: none;
  position: relative;
}
.BrokerRegister ul li:after {
  content: '';
  margin: 0 5px 0 -15px;
}
.txt-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding-left: 10px !important;
  border-bottom: 1px solid #e6ecf0;
}
.no-btnprime {
  background-color: transparent;
  border-color: transparent;
}
.no-btnprime:hover {
  background-color: transparent;
  border-color: transparent;
}
.no-btnprime:focus {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.file-upload {
  height: 40px;
  width: 40px;
  border-radius: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid #FFFFFF;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #5a4099 50%, #FFFFFF 50%);
  background-size: 100% 200%;
  transition: all 1s;
  color: #FFFFFF;
  font-size: 20px;
}
.file-upload input[type='file'] {
  height: 40px;
  width: 40px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer !important;
}
.file-upload:hover {
  background-position: 0 -100%;
  color: #ec008c;
}
input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: transparent !important;
  color: -internal-light-dark(black, white) !important;
}
.page-link {
  color: #5a4099;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #5a4099;
  border-color: #5a4099 !important;
}
.movoTbl th {
  background-color: #5a4099;
  color: #fff;
  font-weight: normal;
  padding-top: 10px;
  padding-bottom: 10px;
}
.pagination {
  justify-content: right;
}
.btn-action {
  color: #9782c8;
  font-size: 16px;
  background-color: transparent;
  padding: 5px;
  border-color: transparent;
}
.btn-action.btn-secondary {
  background-color: transparent;
}
.btn-action:hover {
  color: #e6007e;
  background-color: transparent;
  border-color: transparent;
}
th {
  white-space: nowrap;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em;
  color: #000;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
  font-size: 20px;
  background-color: transparent;
}
.alert {
  padding-bottom: 15px;
}
.alert p {
  margin-bottom: 0px;
  font-size: 11px;
}
.alert p svg {
  fill: #e6007e !important;
}
.inValid-feedback {
  font-size: 11px;
  color: #e6007e;
}
td {
  font-size: 13px;
  color: #666;
  vertical-align: middle;
  padding: 0.2rem 0.5rem !important;
  word-wrap: break-word;
}
.form-control {
  font-size: 13px;
  border-radius: 0px;
  line-height: 2;
}
.modal-header {
  background-color: #5a4099;
}
.modal-header .modal-title {
  color: #fff;
  font-size: 16px !important;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #bcbec0;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  border: none !important;
}
.close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em;
  color: #fff;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
  font-size: 20px;
  background-color: transparent;
}
.btn-primary:focus {
  border: 1px solid #636363;
  background-color: transparent;
  color: #636363;
  box-shadow: none;
}
.custom-m {
  margin-top: 3px;
  margin-right: 5px;
}
.nav-link {
  padding: 0rem 0.1rem;
}
.nav-link:hover {
  color: #e6007e;
}
.caret-4-desc {
  display: inline-block;
  width: 0px;
  height: 0px;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  margin: 10px 6.5px;
}
.caret-4-asc {
  display: inline-block;
  width: 0px;
  height: 0px;
  margin-left: 2px;
  vertical-align: middle;
  border-bottom: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  margin: 10px 6.5px;
}
.img-thumb {
  height: 200px;
  width: auto;
  object-fit: cover;
}
.card {
  position: relative;
}
.card img {
  width: 100%;
  height: 150px;
  object-fit: contain;
}
.card .img-thumb {
  position: relative;
}
.card .img-thumb::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
.card .btn-group {
  display: none;
  position: absolute;
  top: 0px;
  right: 5px;
}
.card:hover .btn-group {
  display: block;
}
.img-profile {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.icn-style {
  background-color: transparent;
  border-radius: 50px;
  height: 20px;
  width: 20px !important;
  background-color: #ececec;
  padding: 7px;
  color: #b6b2b2;
  border: 1px solid #fff;
}
.icn-style:hover {
  background: linear-gradient(132deg, #e6007e 0%, #5b358c 100%);
  color: #fff !important;
  border: 1px solid #fff;
  border-radius: 50px;
  height: 20px;
  width: 20px !important;
  padding:7px;
}
ul {
  margin: 0px;
  padding: 0px;
}
ul.toggle-switch li {
  margin: 0px;
  padding: 0px;
  display: inline-block;
}
ul.toggle-switch li button {
  padding: 11px 12px;
  background-color: #8c79b9;
  color: #fff;
}
ul.toggle-switch li button .svg-inline--fa {
  font-size: 14px;
}
ul.toggle-switch li button:hover {
  background-color: #8c79b9;
  color: #5a4099;
}
ul.toggle-switch li button.btn-primary:focus {
  background-color: #5a4099;
  color: #fff;
}
ul.toggle-switch li:first-child button {
  border-radius: 50px 0px 0px 50px;
}
ul.toggle-switch li:first-child button.btn-primary {
  border: none;
}
ul.toggle-switch li:last-child button {
  border-radius: 0px 50px 50px 0px;
}
ul.toggle-switch li:last-child button.btn-primary {
  border: none;
}
.carousel {
  width: 100%;
}
.carousel-control-prev {
  left: -34px;
  background-color: #cccbcb;
}
.carousel-control-prev {
  position: absolute;
  top: 135px;
  bottom: 135px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  padding: 0;
  color: #c60f0f;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
.carousel-control-next {
  right: -26px;
  position: absolute;
  top: 135px;
  bottom: 135px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  padding: 0;
  color: #c60f0f;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
.pos-toast {
  top: 90px;
  right: 20px;
  z-index: 1;
  position: fixed;
}
hr {
  margin: 0.6rem 0;
}
.btn-primary:disabled {
  color: #fff;
  background-color: #663b8f;
  border-color: #663b8f;
}
.form-control.is-valid {
  border: 1px solid #5a4099;
}
.pos-br {
  position: absolute;
  top: 10px;
  right: 10px;
}
.address-card {
  border-bottom: 1px solid #e6007e;
  position: relative;
}
.address-card .btn-circle {
  border-radius: 50%;
  background-color: #e6007f5d;
  border-color: transparent;
  width: 40px;
  height: 40px;
  padding: 5px;
  opacity: 0.3;
  transition: 0.3s ease all;
}
.address-card .card-text {
  margin-bottom: 0px;
}
.address-card .card-text .list-group {
  color: #636363;
  font-size: 13px;
}
.address-card .card-text .list-group .list-group-item {
  padding: 0.5rem 0rem !important;
}
.address-card .card-text .list-group .list-group-item .svg-inline--fa {
  color: #e6007e;
}
.address-card .card-text .list-group .list-group-item a {
  color: #212529;
}
.address-card:hover .btn-circle {
  border-radius: 50%;
  background-color: #e6007e;
  border-color: #e6007e;
  width: 40px;
  height: 40px;
  padding: 5px;
  opacity: 1;
  transition: 0.3s ease all;
}
.btn-circle {
  border-radius: 50%;
  background-color: #e6007e;
  border-color: transparent;
  width: 40px;
  height: 40px;
  padding: 5px;
  opacity: 1;
  transition: 0.3s ease all;
}
.btn-circle:hover {
  border-radius: 50%;
  background: linear-gradient(132deg, #e6007e 0%, #5b358c 100%) !important;
  border-color: #e6007e;
  width: 40px;
  height: 40px;
  padding: 5px;
  opacity: 1;
  transition: 0.3s ease all;
}
.frm-address .form-control {
  border: 1px solid #cccccc;
}
.bg-success .toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #fff;
  background-color: #5b409941 !important;
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
  text-transform: uppercase;
  font-size: 12px;
}
.bg-success {
  --bs-bg-opacity: 1;
  background-color: #5b409986 !important;
  color: #fff;
}
.imp {
  color: #e6007e;
  font-size: 12px;
}
.cnt-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.cnt-info .svg-inline--fa {
  font-size: 30px;
  color: #e6007e;
  margin-right: 10px;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}
.cnt-info:hover .svg-inline--fa {
  transform: rotateY(180deg);
}
.dataTables_length {
  margin-bottom: 10px;
}
.dataTables_filter input {
  border-radius: 50px !important;
}
.dataTables_wrapper .dataTables_paginate {
  padding-top: 0.8em;
  font-size: 12px;
  font-weight: 500;
}
.dataTables_wrapper .paginate_button {
  border-radius: 50% !important;
  min-width: 2rem !important;
}
.dataTables_wrapper .dataTables_info {
  clear: both;
  float: left;
  padding-top: 0.755em;
  font-size: 12px;
  font-weight: 500;
  color: #5a4099 !important;
}
.dataTables_wrapper .dataTables_filter {
  font-size: 12px;
  font-weight: 500;
}
.dataTables_wrapper .dataTables_length {
  font-size: 12px;
  font-weight: 500;
}
.w-75 {
  border-radius: 5px;
}
.card-link + .card-link {
  margin-left: 0.1rem;
}

#custom-cnt .trade-banner {
  height: 300px;
  background: url(../images/Partnership-Handshake.jpg) center center no-repeat;
  background-size: cover;
  position: relative;
}
#custom-cnt .trade-banner .pro-img {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: rgba(233, 0, 127, 0.5);
  border-radius: 50px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  font-size: 20px;
}
#custom-cnt .trade-banner .pro-img a {
  color: #fff;
  text-decoration: none;
}
#custom-cnt .trade-banner .pro-img a:hover {
  color: #5a4099;
}
#custom-cnt h2 {
  font-size: 40px;
  font-weight: bold;
  color: #ff0000;
}
#custom-cnt .icn-lsting {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  color: #6d747a;
  line-height: 24px;
}
#custom-cnt .icn-lsting li {
  margin: 0px;
  padding: 0px;
  list-style: none;
  margin-bottom: 25px;
  position: relative;
  padding-left: 40px;
}
#custom-cnt .icn-lsting li::before {
  position: absolute;
  content: '';
  display: inline-flex;
  height: 2.3em;
  width: 2.3em;
  background-image: url(../images/bullet.svg);
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 0.5em;
  left: 0;
}
#custom-cnt .cta-banner {
  background: url(../images/Partnership-Handshake.jpg) center center no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  z-index: 1;
  padding: 50px 0px 40px;
}
#custom-cnt .cta-banner::after {
  content: "";
  background: #e9007f;
  background: linear-gradient(0deg, rgba(233, 0, 127, 0.59427521) 0%, rgba(91, 53, 140, 0.65309874) 100%);
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  color: #fff;
}
#custom-cnt .cta-banner ul {
  color: #fff;
  margin: 0px;
  padding: 0px;
}
#custom-cnt .cta-banner ul li {
  margin: 0px;
  padding: 0px;
  list-style: none;
  color: #fff;
  padding-left: 65px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
}
#custom-cnt .numbering {
  border-radius: 50px;
  border: 1px solid #fff;
  height: 50px;
  width: 50px;
  text-align: center;
  display: flex;
  position: absolute;
  left: 0px;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 200;
}
#custom-cnt .info-box {
  display: flex;
  flex-direction: row;
}
#custom-cnt .info-box .info-icn {
  margin-right: 15px;
}
#custom-cnt .info-box .info-icn img {
  width: 50px;
  height: auto;
}
#custom-cnt .btn-default {
  border: 1px solid #f2f2f2;
  border-radius: 50px;
  padding: 8px 20px;
  color: #fff;
}
#custom-cnt .btn-default:hover {
  background-color: #fff;
  color: #5a4099;
}
.bdr-bm {
  border-bottom: 1px solid #ccc;
}
address {
  margin-bottom: 0px;
}
.extra-space {
  margin-bottom: 70px !important;
}
.gallery {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.gallery li {
  width: 240px;
  height: 160px;
  justify-content: space-evenly;
  margin: 20px 20px 30px 0px;
  position: relative;
  z-index: 1;
}
.gallery li .icn-abso {
  opacity: 0;
}
.gallery li img {
  object-fit: contain;
  width: 100%;
  height: 160px;
  opacity: 1;
  transition: 180ms opacity ease-in-out;
  border: 1px solid #ccc !important;
  padding: 12px !important;
  margin-bottom: 5px;
  background-color: #fff !important;
  transition: 0.3s ease all;
}
.gallery li .overlay-cnt {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 83%;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease all;
  z-index: 11;
}
.gallery li:hover .overlay-cnt {
  opacity: 1;
  transition: 0.3s ease all;
}
.gallery li:hover .overlay-cnt .img-hd {
  color: #fff;
}
.gallery li:hover .icn-abso {
  opacity: 1;
}
.gallery li:hover img {
  background-color: #ededed !important;
}
.gallery .btn-bdr {
  border-radius: 50px;
  font-size: 12px;
  text-transform: uppercase;
  padding: 9px 25px !important;
  margin-right: 5px;
  background: #e6007e;
  background: linear-gradient(132deg, #e6007e 0%, #5b358c 100%);
  color: #fff !important;
  border: 2px solid #fff;
}
.gallery .btn-bdr:hover {
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px solid #fff;
  color: #000;
}
.gallery .img-hd {
  text-transform: capitalize;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.staff-gallery {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.staff-gallery li {
  width: 180px;
  height: 300px;
  justify-content: space-evenly;
  margin: 20px 8px 30px 8px !important;
  text-align: center;
  position: relative;
  z-index: 1;
}
.staff-gallery li .icn-abso {
  opacity: 0;
}
.staff-gallery li img {
  object-fit: cover;
  width: 100%;
  height: 200px;
  opacity: 1;
  transition: 180ms opacity ease-in-out;
  border: 1px solid #ccc !important;
  padding: 8px !important;
  margin-bottom: 15px;
  background-color: #fff !important;
  transition: 0.3s ease all;
  object-position: top center;
}
.staff-gallery li .overlay-cnt {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 83%;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease all;
  z-index: 11;
}
.staff-gallery li:hover .overlay-cnt {
  opacity: 1;
  transition: 0.3s ease all;
}
.staff-gallery li:hover .overlay-cnt .img-hd {
  color: #fff;
}
.staff-gallery li:hover .icn-abso {
  opacity: 1;
}
.staff-gallery li:hover img {
  background-color: #ededed !important;
}
.staff-gallery .btn-bdr {
  border-radius: 50px;
  font-size: 12px;
  text-transform: uppercase;
  padding: 9px 25px !important;
  background: #e6007e;
  background: linear-gradient(132deg, #e6007e 0%, #5b358c 100%);
  color: #fff !important;
  border: 2px solid #fff;
}
.staff-gallery .btn-bdr:hover {
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px solid #fff;
  color: #000;
}
.staff-gallery .img-hd {
  text-transform: capitalize;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.event-gallery {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.event-gallery li {
  width: 180px;
  height: 130px;
  justify-content: space-evenly;
  margin: 20px 8px 50px 8px !important;
  text-align: center;
  position: relative;
  z-index: 1;
}
.event-gallery li .icn-abso {
  opacity: 0;
}
.event-gallery li img {
  object-fit: cover;
  width: 100%;
  height: 130px;
  opacity: 1;
  transition: 180ms opacity ease-in-out;
  border: 1px solid #ccc !important;
  padding: 8px !important;
  margin-bottom: 15px;
  background-color: #fff !important;
  transition: 0.3s ease all;
  object-position: center center;
}
.event-gallery li .overlay-cnt {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 83%;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease all;
  z-index: 11;
}
.event-gallery li:hover .overlay-cnt {
  opacity: 1;
  transition: 0.3s ease all;
}
.event-gallery li:hover .overlay-cnt .img-hd {
  color: #fff;
}
.event-gallery li:hover .icn-abso {
  opacity: 1;
}
.event-gallery li:hover img {
  background-color: #ededed !important;
}
.event-gallery .btn-bdr {
  border-radius: 50px;
  font-size: 12px;
  text-transform: uppercase;
  padding: 9px 25px !important;
  background: #e6007e;
  background: linear-gradient(132deg, #e6007e 0%, #5b358c 100%);
  color: #fff !important;
  border: 2px solid #fff;
}
.event-gallery .btn-bdr:hover {
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px solid #fff;
  color: #000;
}
.event-gallery .img-hd {
  text-transform: capitalize;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.insurer-event-gallery {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.insurer-event-gallery li {
  width: 144px;
  height: 120px;
  justify-content: space-evenly;
  margin: 20px 8px 50px 8px !important;
  text-align: center;
  position: relative;
  z-index: 1;
}
.insurer-event-gallery li .icn-abso {
  opacity: 0;
}
.insurer-event-gallery li img {
  object-fit: cover;
  width: 100%;
  height: 110px;
  opacity: 1;
  transition: 180ms opacity ease-in-out;
  border: 1px solid #ccc !important;
  padding: 8px !important;
  margin-bottom: 15px;
  background-color: #fff !important;
  transition: 0.3s ease all;
  object-position: center center;
}
.insurer-event-gallery li .overlay-cnt {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 83%;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease all;
  z-index: 11;
}
.insurer-event-gallery li:hover .overlay-cnt {
  opacity: 1;
  transition: 0.3s ease all;
}
.insurer-event-gallery li:hover .overlay-cnt .img-hd {
  color: #fff;
}
.insurer-event-gallery li:hover .icn-abso {
  opacity: 1;
}
.insurer-event-gallery li:hover img {
  background-color: #ededed !important;
}
.insurer-event-gallery .btn-bdr {
  border-radius: 50px;
  font-size: 12px;
  text-transform: uppercase;
  padding: 9px 25px !important;
  background: #e6007e;
  background: linear-gradient(132deg, #e6007e 0%, #5b358c 100%);
  color: #fff !important;
  border: 2px solid #fff;
}
.insurer-event-gallery .btn-bdr:hover {
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px solid #fff;
  color: #000;
}
.insurer-event-gallery .img-hd {
  text-transform: capitalize;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.search-box {
  border-radius: 50px;
  height: 32px;
  border: 1px solid #aaa;
  padding: 10px;
}
.fullscreen-modal.modal-dialog {
  position: fixed;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: rgba(0, 0, 0, 0.4);
}
.fullscreen-modal .modal-content {
  position: fixed;
  top: 50px;
  left: 50px;
  right: 50px;
  bottom: 50px;
  background-color: #5b358c;
}
.modal-90w {
  width: 90%;
  max-width: none !important;
}
.img-event {
  width: 100%;
  height: 150px;
  object-fit: cover;
}
.card-footer {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-color: transparent;
}
.text-muted {
  text-transform: capitalize;
  width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
}
button {
  cursor: pointer;
  z-index: 99;
}
/***********/
.flip-box {
  background-color: transparent;
  width: 100%;
  height: 160px;
  perspective: 1000px;
  /* Remove this if you don't want the 3D effect */
}
.flip-box-front img {
  width: 1000%;
  height: 100%;
  object-fit: contain;
}
/* This container is needed to position the front and back side */
.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box-inner .icn-liked{
  position: absolute; top:5px; right: 5px; width: 27px; height: 27px; border-radius: 50px; padding: 5px; background-color: #e5007d; color: #fff; font-size: 15px; line-height: 15px; border:1px solid #fff;
}



/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}
/* Position the front and back side */
.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden ;
}
/* Style the front side (fallback if image is missing) */
.flip-box-front {
  color: black;
  padding: 10px;
  border: 1px solid #ccc;
}
.flip-box-front img {
  width: 1000%;
  height: 100%;
  object-fit: contain;
}
/* Style the back side */
.flip-box-back {
  color: white;
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.flip-box-back .btn-action {
  background-color: #fff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  line-height: 20px;
  color: #5a4099;
}
.flip-box-back .btn-action:hover {
  color: #e5007d;
}
.img-list {
  width: 80px;
  height: 60px;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px;
  object-fit: contain;
  background-color: #fff;
}
.company-logo {
  width: 120px;
  height: auto;
  max-height: 80px;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px;
  object-fit: contain;
  background-color: #fff;
  min-height: 70px;
}
.img-list-profile {
  width: 60px;
  height: 60px;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px;
  object-fit: contain;
  background-color: #fff;
}
.shadow {
  box-shadow: 3px 2px 5px 0px rgba(0, 0, 0, 0.63);
  -webkit-box-shadow: 3px 2px 5px 0px rgba(0, 0, 0, 0.63);
  -moz-box-shadow: 3px 2px 5px 0px rgba(0, 0, 0, 0.63);
}
table.dataTable.no-footer {
  border-bottom: 1px solid #e3e3e3;
}
.bg-error {
  background-color: #e5007d;
  color: #fff;
}
.bg-error .toast-header {
  color: #000;
}
.staff-detail .staff-detail-img {
  object-fit: cover;
  width: 100%;
  height: 400px;
  object-position: top center;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 5px;
}
ul {
  margin: 0px;
  padding: 10px 0px;
}
ul.dtr-details li {
  margin: 0px;
  padding: 0px;
  list-style: none;
  margin-bottom: 10px;
}
ul.dtr-details li span.dtr-title {
  color: #5a4099;
  font-weight: 600;
}
ul.dtr-details li span.dtr-title::after {
  content: ": ";
}
.pop-staff-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* word-break: break-all; */
  text-align: justify;
}
.pop-staff-info .svg-inline--fa {
  margin-top: 3px;
  margin-right: 5px;
  color: #808388;
}
.pop-staff-info:hover .svg-inline--fa {
  margin-top: 3px;
  margin-right: 5px;
  color: #5a4099;
}
.btn-bdr .btn-action .btn .btn-secondary .btn-primary {
  z-index: 99;
}
ul.dtr-details li span.dtr-title:end {
  content: ":";
  color: red;
}
#sidebarWrap {
  height: 400px;
  width: 210px;
  float: right;
  position: relative;
  box-shadow: none;
  border: none;
  margin: 0;
  padding: 0;
}
#main {
  width: 670px;
  height: 4000px;
  background: #333333;
}
.note-btn-group .btn-default {
  border: 1px solid #b1b1b1;
  border-radius: 50px;
  padding: 8px 20px;
  color: #303030;
}
.btn-bdr-style2 {
  background-color: transparent;
  border: 1px solid #dadada;
  color: #fff;
  border-radius: 80px;
  font-size: 13px;
  margin-right: 5px;
  padding: 12px 20px !important;
  background: linear-gradient(132deg, #e6007f9c 0%, #5b358ca9 100%);
  transition: 0.3s ease all;
}
.btn-bdr-style2:hover {
  background: #e6007e;
  background: linear-gradient(132deg, #e6007e 0%, #5b358c 100%);
  color: #fff !important;
  border: 1px solid #fff;
  transition: 0.3s ease all;
}
/* Sticky our navbar on window scroll */
#sidebar-nav.sticky {
  position: fixed;
  top: 5px;
}
.mr-l {
  margin-left: 280px;
}
.footer-mr-l {
  margin-left: 256px;
}
.navbar-nav .nav-item.avatar-lnk {
  padding-top: 20px;
  height: 43px;
}
.navbar-nav .nav-item.avatar-lnk:hover {
  color: #fff !important;
}
.navbar-nav .nav-item.avatar-lnk:hover .nav-link:hover {
  color: #fff !important;
}
.navbar-nav .nav-item.avatar-lnk .nav-link:hover {
  color: #fff !important;
}
.wspace-pre-line {
  white-space: pre-line;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0 !important;
}



.like{
  
 
    background: #e6007e;
    background: linear-gradient(132deg, #e6007e 0%, #5b358c 100%);
 
  color: #fff !important;
  border: 1px solid #fff;
  border-radius: 50px !important;
  
}

.like svg{animation: zoominoutsinglefeatured 15s forwards;}

@keyframes zoominoutsinglefeatured {
  0% {
      transform: scale(.6,.6);
  }
  50% {
      transform: scale(1.2,1.2);
  }
  /* 100% {
      transform: scale(1,1);
  } */
}

.single-featured-image-header img {
animation: zoominoutsinglefeatured 5s forwards;
}