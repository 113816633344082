/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) {
  /* CSS */
}
/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
@media (min-width: 1025px) and (max-width: 1280px) {
  /* CSS */
}
/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
@media (min-width: 768px) and (max-width: 1024px) {
  /* CSS */
}
/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* CSS */
}
/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
@media (min-width: 481px) and (max-width: 767px) {
  /* CSS */
}
/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
@media (min-width: 320px) and (max-width: 480px) {
  /* CSS */
  .content-page {
    overflow: hidden;
    margin-left: 0px;
    padding: 0px 0px 0px 0px;
    min-height: 100vh;
    transition: all 0.3s ease-in-out;
    width: 100%;
  }
  .navbar-toggler {
    border: none;
    padding: 0px;
    font-size: 14px;
    background-color: transparent;
    border-color: transparent !important;
    box-shadow: none;
  }
  .navbar-toggler:focus {
    border: none;
    text-decoration: none;
    outline: 0;
    box-shadow: none;
  }
  .navbar {
    padding: 10px 0px;
  }
  .navbar-nav .nav-item {
    display: inline-block;
    width: 100%;
    padding: 0px 15px;
    border-bottom: 1px solid #ec008c;
  }
  .navbar-nav .nav-item:hover {
    background-color: #e6e3e3;
    padding-top: 20px;
  }
  .navbar-nav .nav-item.avatar-lnk {
    padding-top: 20px;
    height: 43px;
  }
  .navbar-nav .nav-item.avatar-lnk:hover {
    color: #303030;
  }
  .navbar-nav .nav-item.avatar-lnk .nav-link:hover {
    color: #303030 !important;
  }
  .navbar-collapse {
    background-color: #da0279;
    margin-bottom: 14px;
  }
  .login-box {
    width: 90%;
    padding: 20px;
  }
  .cta-banner {
    padding: 30px;
  }
  .gallery {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
  }
  .gallery li {
    width: 100%;
    height: 160px;
    justify-content: space-evenly;
    margin: 0px 0px 70px 0px;
    position: relative;
    z-index: 1;
  }
  .gallery li .icn-abso {
    opacity: 0;
  }
  .gallery li img {
    object-fit: contain;
    width: 100%;
    height: 160px;
    opacity: 1;
    transition: 180ms opacity ease-in-out;
    border: 1px solid #ccc !important;
    padding: 12px !important;
    margin-bottom: 5px;
    background-color: #fff !important;
    transition: 0.3s ease all;
  }
  .gallery li .overlay-cnt {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 83%;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease all;
    z-index: 11;
  }
  .gallery li:hover .overlay-cnt {
    opacity: 1;
    transition: 0.3s ease all;
  }
  .gallery li:hover .overlay-cnt .img-hd {
    color: #fff;
  }
  .gallery li:hover .icn-abso {
    opacity: 1;
  }
  .gallery li:hover img {
    background-color: #ededed !important;
  }
  .gallery .btn-bdr {
    border-radius: 50px;
    font-size: 12px;
    text-transform: uppercase;
    padding: 9px 25px !important;
    background: #e6007e;
    background: linear-gradient(132deg, #e6007e 0%, #5b358c 100%);
    color: #fff !important;
    border: 2px solid #fff;
  }
  .gallery .btn-bdr:hover {
    background-color: rgba(255, 255, 255, 0.8);
    border: 2px solid #fff;
    color: #000;
  }
  .gallery .img-hd {
    text-transform: capitalize;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .staff-gallery {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
  }
  .staff-gallery li {
    width: 100%;
    height: auto;
    justify-content: space-evenly;
    margin: 20px 8px 15px 8px !important;
    text-align: center;
    position: relative;
    z-index: 1;
  }
  .staff-gallery li .icn-abso {
    opacity: 0;
  }
  .staff-gallery li img {
    object-fit: cover;
    width: 100%;
    height: 300px;
    opacity: 1;
    transition: 180ms opacity ease-in-out;
    border: 1px solid #ccc !important;
    padding: 8px !important;
    margin-bottom: 15px;
    background-color: #fff !important;
    transition: 0.3s ease all;
    object-position: top center;
  }
  .staff-gallery li .overlay-cnt {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 83%;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease all;
    z-index: 11;
  }
  .staff-gallery li:hover .overlay-cnt {
    opacity: 1;
    transition: 0.3s ease all;
  }
  .staff-gallery li:hover .overlay-cnt .img-hd {
    color: #fff;
  }
  .staff-gallery li:hover .icn-abso {
    opacity: 1;
  }
  .staff-gallery li:hover img {
    background-color: #ededed !important;
  }
  .staff-gallery .btn-bdr {
    border-radius: 50px;
    font-size: 12px;
    text-transform: uppercase;
    padding: 9px 25px !important;
    background: #e6007e;
    background: linear-gradient(132deg, #e6007e 0%, #5b358c 100%);
    color: #fff !important;
    border: 2px solid #fff;
  }
  .staff-gallery .btn-bdr:hover {
    background-color: rgba(255, 255, 255, 0.8);
    border: 2px solid #fff;
    color: #000;
  }
  .staff-gallery .img-hd {
    text-transform: capitalize;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
  .event-gallery {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .event-gallery li {
    width: 100%;
    height: 210px;
    justify-content: space-evenly;
    margin: 20px 0px 30px 0px !important;
    text-align: center;
    position: relative;
    z-index: 1;
  }
  .event-gallery li .icn-abso {
    opacity: 0;
  }
  .event-gallery li img {
    object-fit: cover;
    width: 100%;
    height: 210px;
    opacity: 1;
    transition: 180ms opacity ease-in-out;
    border: 1px solid #ccc !important;
    padding: 8px !important;
    margin-bottom: 5px;
    background-color: #fff !important;
    transition: 0.3s ease all;
    object-position: center center;
  }
  .event-gallery li .overlay-cnt {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 83%;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease all;
    z-index: 11;
  }
  .event-gallery li:hover .overlay-cnt {
    opacity: 1;
    transition: 0.3s ease all;
  }
  .event-gallery li:hover .overlay-cnt .img-hd {
    color: #fff;
  }
  .event-gallery li:hover .icn-abso {
    opacity: 1;
  }
  .event-gallery li:hover img {
    background-color: #ededed !important;
  }
  .event-gallery .btn-bdr {
    border-radius: 50px;
    font-size: 12px;
    text-transform: uppercase;
    padding: 9px 25px !important;
    background: #e6007e;
    background: linear-gradient(132deg, #e6007e 0%, #5b358c 100%);
    color: #fff !important;
    border: 2px solid #fff;
  }
  .event-gallery .btn-bdr:hover {
    background-color: rgba(255, 255, 255, 0.8);
    border: 2px solid #fff;
    color: #000;
  }
  .event-gallery .img-hd {
    text-transform: capitalize;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
  .pos-toast {
    top: 80px;
    right: 5px;
    z-index: 1;
    position: fixed;
  }
  .pd-sm {
    padding: 10px;
    margin-bottom: 1px;
    border-bottom: 1px solid #e6ecf0;
    word-wrap: break-word;
    display: block;
  }
  .mvp-sidebar {
    position: unset;
    z-index: 1000;
    margin-top: 100px;
    width: 100%;
    height: auto;
    background: #fff;
    transition: all 0.6s ease-in-out;
    border-right: none;
  }
  .mvp-sidebar.insurere-p {
    top: 20px;
  }
  .mvp-sidebar .nav-link {
    display: block;
    text-decoration: none;
    font-size: 13px;
    position: relative;
    color: #393f49;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
  }
  .mvp-sidebar .nav-link.active {
    color: #fff;
    background-color: #e6007e;
  }
  .mvp-sidebar .nav-link.active .fas,
  .mvp-sidebar .nav-link.active .svg-inline--fa,
  .mvp-sidebar .nav-link.active .far {
    color: #fff;
  }
  .mvp-sidebar .nav-link span {
    margin-right: 5px;
  }
  .mvp-sidebar .nav-link .fas,
  .mvp-sidebar .nav-link .svg-inline--fa,
  .mvp-sidebar .nav-link .far {
    color: #bcbec0;
    border-radius: 50px;
    height: 30px;
    width: 40px;
    padding: 7px;
    text-align: center;
    transition: 0.3s ease all;
  }
  .mvp-sidebar .nav-link:hover {
    color: #fff;
    background-color: #e6007e;
  }
  .mvp-sidebar .nav-link:hover .fas,
  .mvp-sidebar .nav-link:hover .svg-inline--fa,
  .mvp-sidebar .nav-link:hover .far {
    color: #fff;
    transition: 0.3s ease all;
  }
  .mvp-sidebar .list-group {
    font-size: 13px;
  }
  .mvp-sidebar .list-group .svg-inline--fa {
    color: #bcbec0;
  }
  .mvp-sidebar .list-group span {
    color: #393f49;
  }
  .mvp-sidebar .list-group a {
    color: #393f49;
  }
  .mvp-sidebar .list-group a:hover .svg-inline--fa {
    color: #e6007e;
  }
  .txt-c {
    text-align: center;
  }
  .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.8) !important;
    border-bottom: 1px solid #ec008c;
    display: block;
    width: 100%;
    text-align: center;
    padding: 10px;
  }
  .navbar-nav .nav-link:hover {
    color: #fff;
  }
  .mr-l {
    margin-left: 0px;
  }
  .footer-mr-l {
    margin-left: 0px;
  }
  .navbar-nav .nav-item.avatar-lnk:hover .nav-link:hover {
    color: #303030 !important;
  }
  #mvid {
    margin-top: 20px;
  }
}
